import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'
import { formatPrice } from '../../utils/formatPrice'

export const productModel = ({
  available = true,
  averageRate,
  brand,
  category,
  chillpackDuration,
  color = '',
  currency,
  dealType,
  insurancePrice,
  insuranceTitle,
  isSwapEnabled,
  list,
  listingId,
  mainListing,
  merchantId,
  model,
  name,
  hasReviews,
  numberReviewsDisplayed,
  numberTotalReviews,
  orderlineId,
  position = 1,
  productPurchaseDate,
  price,
  provider,
  // number of products selected by the user (funnel)
  quantity,
  // available stock of products
  stock,
  shipper,
  shippingDelay,
  shippingOptions = [],
  shippingPrice,
  shippingEvent,
  hasExpressShipping,
  source,
  splitPayment,
  trustpackDuration,
  uuid,
  variant,
  warrantyDuration,
  productAlgoliaReranked,
  sellerOrigin,
  isInsuranceEligible,
  insuranceEligibleOffers,
  insurancePaymentType,
  hasNewBattery,
  hasNewBatteryInStock,
  hasPremium,
  hasPremiumInStock,
  specialOfferType,
  productPriceTagType,
  productPriceTagSelected,
  mobilePlanOffers,
  mobilePlanOfferSelected,
  partnerPromoCodes,
  productRecoComponentPosition,
  productComponent,
}: EventData) => {
  return removeEmptyValuesInObject({
    average_rate: averageRate,
    product_available: available,
    product_brand: brand,
    product_category: category,
    product_chillpack_months: chillpackDuration,
    product_color: color,
    product_component: productComponent,
    product_deal_type: dealType,
    product_display_type: source,
    product_displayvendor: provider,
    product_has_review: hasReviews,
    product_id: uuid,
    product_insurance_price: insurancePrice,
    product_insurance_title: insuranceTitle,
    product_insurance_payment_type: insurancePaymentType,
    product_insurance_eligible_offers: insuranceEligibleOffers,
    product_is_swap_eligible: isSwapEnabled,
    product_list: list,
    product_listingID: listingId && String(listingId),
    product_model: model,
    product_main_listing: mainListing,
    product_name: name,
    product_nb_reviews: numberTotalReviews,
    product_orderline_id: orderlineId,
    product_paymentmethod: splitPayment,
    product_position: position,
    product_price: formatPrice({ amount: price, currency }),
    product_purchase_date: productPurchaseDate,
    product_quantity: quantity && Number(quantity),
    product_reco_component_position: productRecoComponentPosition,
    product_reviews_displayed: numberReviewsDisplayed,
    product_seller_id: merchantId && String(merchantId),
    product_seller_origin: sellerOrigin,
    product_shipper: shipper,
    product_shipping_delay: shippingDelay,
    product_has_shipping_express: hasExpressShipping,
    product_shipping_options: shippingOptions,
    product_shipping_price: formatPrice({ amount: shippingPrice, currency }),
    product_shipping_event: shippingEvent,
    product_trustpack_duration: trustpackDuration,
    product_units_in_stock: stock && Number(stock),
    product_variant: variant,
    product_warranty_duration: warrantyDuration,
    product_algolia_reranked: productAlgoliaReranked,
    product_is_insurance_eligible: isInsuranceEligible,
    product_has_new_battery: hasNewBattery,
    product_battery_stock: hasNewBatteryInStock,
    product_has_premium: hasPremium,
    product_premium_stock: hasPremiumInStock,
    product_special_offer_type: specialOfferType,
    product_price_tag_displayed: productPriceTagType,
    product_price_tag_selected: productPriceTagSelected,
    product_mobile_plan_eligible_offers: mobilePlanOffers,
    product_mobile_plan_selected_offer: mobilePlanOfferSelected,
    product_partner_promo_codes_eligible_offers: partnerPromoCodes,
  })
}

export const productModelCollection = (products: EventData[] = []) => {
  const productsList = Array.isArray(products) ? products : [products]

  return productsList.map((product, index) => ({
    ...productModel({ ...product, position: product.position || index + 1 }),
  }))
}
